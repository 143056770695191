import React from 'react';

const Loader = () => (
  <div className="load-wrap">
    <div className="loadingcircle"></div>
    <div className="loadingcircle"></div>
    <div className="loadingcircle"></div>
    <div className="loadingcircle"></div>
  </div>
);

export default Loader;

import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loading from '../../components/Loading/Common';
import { history } from '../../helpers/history';
import PrivateRoute from '../../helpers/privateRoute';
import ErrorBoundary from '../Utilities/ErrorBoundary';

const Login = Loadable({
  loader: () => import('../../components/Login'),
  loading: Loading,
});
const Dashboard = Loadable({
  loader: () => import('../../components/Dashboard'),
  loading: Loading,
});

const scenarioList = Loadable({
  loader: () => import('../scenarioList'),
  loading: Loading,
});
const RequestAccess = Loadable({
  loader: () => import('../RequestAcess'),
  loading: Loading,
});
const ForgotPassword = Loadable({
  loader: () => import('../../components/Forgotpassword'),
  loading: Loading,
});
const SessionCreation = Loadable({
  loader: () => import('../../components/sessions/FinalForm'),
  loading: Loading,
});
const ScenarioCreation = Loadable({
  loader: () => import('../../components/scenarios/FinalForm'),
  loading: Loading,
});
const RunScenario = Loadable({
  loader: () => import('../../components/RunScenario'),
  loading: Loading,
});

const OptimizedData = Loadable({
  loader: () => import('../../components/OptimizedData'),
  loading: Loading,
});

const Help = Loadable({
  loader: () => import('../Help'),
  loading: Loading,
});
const NotFound = Loadable({
  loader: () => import('../NotFound'),
  loading: Loading,
});

class RouterApp extends Component {
  render() {
    return (
      <>
        <Router history={history}>
          <ErrorBoundary>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/request-access" component={RequestAccess} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <PrivateRoute
                exact
                path="/scenarios-list/:sessionId"
                component={scenarioList}
              />
              <PrivateRoute
                exact
                path="/create-session"
                component={SessionCreation}
              />
              <PrivateRoute
                exact
                path="/edit-session/:sessionId"
                component={SessionCreation}
              />
              <PrivateRoute
                exact
                path="/create-scenario/:sessionId"
                component={ScenarioCreation}
              />
              <PrivateRoute
                exact
                path="/edit-scenario/:sessionId/:scenarioId"
                component={ScenarioCreation}
              />
              <PrivateRoute
                exact
                path="/run-scenario/:sessionId/:scenarioId"
                component={RunScenario}
              />
              <PrivateRoute
                exact
                path="/optimized-data/:sessionId/:scenarioId"
                component={OptimizedData}
              />
              <PrivateRoute exact path="/help" component={Help} />

              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </>
    );
  }
}

export default RouterApp;

let config = {};

if (process.env.REACT_APP_BASE_URL) {
  config = {
    baseURL: process.env.REACT_APP_BASE_URL,
  };
} else {
  config = {
    baseURL: "https://gms.api.azure.analytic-edge.net/",
  };
}

export const appConfig = {
  ...config,
};
